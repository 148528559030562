import React, { Component } from 'react';

import './index.scss'

// import OurStoryPage from './OurStoryPage'
//
// export {OurStoryPage}

import CartBar from './CartBar'
export {CartBar as StickyTopBar}

class Pablitos extends Component {
  render() {
    return (
      <div className="pablitos">
      </div>
    );
  }
}

export class Header extends Component {
  render() {
    return (
      <div className="order-benefits-wrapper">
        <div className="order-benefits">

        <div className="text">
            <p>
                <strong>The Aleppo Grill</strong>
</p>
            {/* <p>visit: <a href="https://thealeppogrill.com/order">thealeppogrill.com</a></p> */}
            <p>Mon-Sat 10am-8pm</p>
            <p>Order Togo and Delivery Below ⤵</p>
            {/* <p>Now with 5% Rewards.</p> */}
          </div>

          {/* <div className="text">
            <p>Aleppo Kitchen of Burbank is now: 
              <br />
                <strong>The Aleppo Grill</strong>
<br />
Same menu, management and great food
<br />
New name and a new website </p>
            <p>visit: <a href="https://thealeppogrill.com/order">thealeppogrill.com</a></p>
            <p>Mon-Sat 10am-8pm</p>
            <p>Order Togo and Delivery Below ⤵</p>
          </div> */}


        </div>
      </div>
    );
  }
}

export default Pablitos;
