import React from "react"

// old blue: #22BEEC

function SvgComponent(props) {
  const bgColor = (props.themeName === 'dark') ?
    "#fdba4a"  :
    "#185da9"
  return (
    <svg
      style={{
        width: "25px",
        marginLeft: "0.5em"
      }}
      viewBox="0 0 222.4 436.3" {...props}>
      <path
        fill={bgColor}
        d="M50.4 366.2c-.6-9.9 0-19.2 5.2-19.2 4 0 4 2.9 4.6 3.5.6.6 0 14.5 0 15.7h-9.8zm11-153.7c-.9.9-45.9 47-45.9 47-3.5 1.7-9.3 2.3-9.3 6.4 0 4 7 5.8 7 5.8v8.7c0 7.5-.6 60.4-.6 63.3s-.6 71.3 0 77.1c.6 5.8 1.7 5.8 3.5 5.8 1.7 0 102.2 1.7 102.2 1.7s.6-73.6.6-76c0-2.3 2.3-16.8 18.6-15.7 16.2 1.1 18 18 18 22.6 0 4.7-.6 69-.6 69h54c1.2 0 1.2-55.6 1.2-57.4 0-1.7 2.3-62.1 1.7-63.9-.6-1.7-22-3.5-22-3.5 0-3.5.6-56.3.6-59.8s.6-64.4.6-67.3c0-2.9-.6-63.6-.6-63.6s4-4.7 4-7-8.7-9.3-8.7-9.3V56c0-1.2-.6-38.9-.6-45.3s-5.2-4.6-11.6-5.2c-6.4-.6-8.7 2.3-8.7 4v12.8c0 5.2-1.1 37.7-1.1 37.7-6.4-7-18.6-39.5-25-40.6-5.2-.9-7.5 8.1-8.7 9.3-1.1 1.2-27.8 59.2-27.8 59.2-6.4 5.2-19.7 15.7-19.7 18.6 0 4.1 2.9 4.7 3.5 5.8.6 2.9-.6 57.2 0 61.3.6 4.1 0 18 0 19.7 0 1.7-.6 16.8-.6 16.8-1.9.1-23.1 1.5-24 2.4"
      />
    </svg>
  )
}

export default SvgComponent
