import React, {useState, useEffect, useRef} from 'react'
import {Link, withRouter} from 'react-router-dom'
import { Subscribe } from 'unstated';
import {round} from 'lodash'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
// import {injectStripe} from 'react-stripe-elements';
import {injectShopContainer} from './ShopDataContainer'
import ErrorBoundary from './ErrorBoundary'

import calcItemPrice from './calcItemPrice'
import calcItemPriceArray from './calcItemPriceArray'
import CartContainer, {injectCartContainer} from './CartContainer'
import makeCloudinaryUrlForPhoto from './makeCloudinaryUrlForPhoto'
import checkIfOrderingOnline from './isOrderingOnline'
import ModgroupDisplay from './CartCheckoutModgroupDisplay'

import DeliveryAddress from '../DeliveryAddress'
import StripePayment from '../StripePayment'
import PaymentRequestButton from '../StripePayment/PaymentRequestButton'
import CashPayment from '../Checkout/CashPayment'
import Cashback from '../Checkout/Cashback'
import PickReadyTime from '../Checkout/PickReadyTime'
import DineInOptions from '../Checkout/DineInOptions'

import CheckoutTips from '../Checkout/CheckoutTips'
import PromoCode from '../Checkout/PromoCode'
import {Helmet} from 'react-helmet'





function CartCheckout (props) {
  const [orderButtonPressed, setOrderButtonPressed] = useState(false)
  const [orderSubmitErrorText, setOrderSubmitErrorText] = useState(null)
  // const [isOrderingOnline, setIsOrderingOnline] = useState(true)

  const stripe = useStripe();
  const elements = useElements();

  const focusRef = useRef()

  const goBack = (e) => {
    e.preventDefault()
    let isFromApp = props.location.state && props.location.state.fromMini
    if (isFromApp) {

      props.history.goBack()
    } else {
      props.history.push({pathname: '/order'})
    }
  };
  useEffect(() => {
    // checkIfOrderingOnline((isOrderingOnline) => setIsOrderingOnline(isOrderingOnline))
    // always scroll to top for this page
    try {window.scrollTo(0,0)} catch (e) {}

    if(focusRef.current) {
      try {
        focusRef.current.focus()
      } catch(e) {
      }
    }
  }, [0])

  useEffect(() => {
    // console.log('hisdfsdfsdfsdf')
    const paymentType = props.cart && props.cart.state.paymentType
    const cartType = props.cart && props.cart.state.cartType
    const disableCash = props.settings && props.settings.disableCash
    const disableCredit = props.settings && props.settings.disableCredit
    const deliveryOnly = props.settings && props.settings.deliveryOnly
    const enableUtensilsPicker = props.settings && props.settings.enableUtensilsPicker
    console.log('debug', enableUtensilsPicker,)
    if ((paymentType !== 'CARD') && disableCash && !window['ranUnsafeFunction' + props.shopId]) {
      console.log('aleppo special 😆')
      window['ranUnsafeFunction' + props.shopId] = true
      props.cart.setStateAndSave({paymentType: "CARD"})
    }
    if ((paymentType !== 'CASH') && disableCredit && !window['ranUnsafeFunction' + props.shopId]) {
      console.log('aleppo special 😆')
      window['ranUnsafeFunction' + props.shopId] = true
      props.cart.setStateAndSave({paymentType: "CASH"})
    }
    if ((cartType === 'PICKUP') && deliveryOnly && !window['ranUnsafeDisableDeliveryFunction' + props.shopId]) {
      console.log('force delivery only')
      window['ranUnsafeDisableDeliveryFunction' + props.shopId] = true
      props.cart.setStateAndSave({cartType: "DELIVERY"})
    }
    if (enableUtensilsPicker && (!props.cart.state.utensilsOption)) {
        console.log('adding forks')
        props.cart.setStateAndSave({utensilsOption: "ADD_UTENSILS"})
      }
    
    // if ((cartType === 'DINEIN') && !props.cart.state.enableSecretDineIn) {
    //   props.cart.setStateAndSave({cartType: "PICKUP"})
    // }
  })


  const handleCreateOrder = async () => {
    setOrderButtonPressed(true)
    if (!stripe || !elements) {
      window.Sentry.captureMessage('attempting to use stripe before loaded')
      return;
    }
    const cardElement = elements.getElement(CardElement)
    let token
    try {
      const result = await stripe.createToken(cardElement)
      token = result.token
      // console.log('TOKEN:', token)
    } catch (e) {
      console.log(e)
    }

    props.cart.createOrder({stripeToken: token}, ({success, text, data}) => {
      if (success) {
        console.log(data)
        if (data.shopId === 'test') {
          props.history.push(`/order-status/${data.orderId}/test`)
        } else {
          props.history.push(`/order-status/${data.orderId}`)
        }
      } else {
        setOrderButtonPressed(false)
        setOrderSubmitErrorText(text)
      }
    })
  }

  const handleTokenEvent = ({complete, token, ...data}) => {
    console.log(token, complete, data)
    props.cart.createOrder({stripeToken: token}, ({success, text, data}) => {
      if (success) {
        console.log(data)
        complete("success")
        if (data.shopId === 'test') {
          props.history.push(`/order-status/${data.orderId}/test`)
        } else {
          props.history.push(`/order-status/${data.orderId}`)
        }
      } else {
        complete("fail")
        setOrderButtonPressed(false)
        setOrderSubmitErrorText(text)
      }
    })
  }


    let shopId
    try {
      shopId = props.shop.shopId
    } catch(e) {

    }
    console.log('checkout page:', shopId)
    // console.log(props.shop.subMenus)

    const cart = props.cart
    let {totalPrice, numberOfMarketPriceItems=0, discount=0, invoice, subtotal, tax, allServiceFee, deliveryFeeInCents, preTipPrimaryCents, preTipTotalWithTax} = cart.getSubtotals()

    const cartHasNoItems = !(cart.state.products.length > 0)
    let paymentType = cart.state.paymentType
    let cartType = cart.state.cartType
    let utensilsOption = cart.state.utensilsOption
    let {agreeToDonate, restaurantDonationCents=199} = cart.state
    // console.log('cart state:', cart.state)
    let settings = props.settings
    if (!settings) { return null }

    let {enableOrdering, minimumPickupSubtotalInCents=0, approxPickupWaitInMinutes=25, showDelivery, showPickup, enableCashback, cashbackPercentageAsInteger, delivery={}, deliveryOnly, disableCash, disableCredit, pretip=true, allowDineIn, enableUtensilsPicker, donation} = settings
    const isOrderingOnline = enableOrdering
    let {enableDonation, allowCustomAmount, enableDonationThankYouPage, defaultAmount, donationPrompt} = donation || {}
    let {maxCashOrderTotalInCents, contactFreeOptions} = settings
    let {minimumDeliverySubtotalInCents=0, disableDelivery, deliveryCreditOnly, defaultOrderStartsDropoffMinutes, notEnoughDriver, notEnoughDriverText} = delivery
    disableDelivery = (disableDelivery === "true") || (disableDelivery === true)

    let outstandingOrderConditions = []
    let disableOrdering = (orderButtonPressed || cartHasNoItems || !isOrderingOnline)
    let didNotReachMinimumDeliverySubtotal = (totalPrice * 100 < minimumDeliverySubtotalInCents)
    let didNotReachMinimumPickupSubtotal = (totalPrice * 100 < minimumPickupSubtotalInCents)
    // console.log('debug:', didNotReachMinimumDeliverySubtotal, totalPrice, minimumDeliverySubtotalInCents)
    if (cart.state.cartType === 'DELIVERY') {
      disableOrdering = disableOrdering || !cart.state.isDeliverable || didNotReachMinimumDeliverySubtotal
      if (didNotReachMinimumDeliverySubtotal) {
        outstandingOrderConditions.push(`add items to reach delivery minimum of $${minimumDeliverySubtotalInCents/100}.`)
      }
      if (!cart.state.isDeliverable) {
        outstandingOrderConditions.push(`Delivery address out of range.`)
      }
    }
    if (cart.state.cartType==='PICKUP') {
      disableOrdering = disableOrdering || didNotReachMinimumPickupSubtotal
      if (didNotReachMinimumPickupSubtotal) {
        outstandingOrderConditions.push(`add items to reach pickup minimum of $${minimumPickupSubtotalInCents/100}.`)
      }
    }
    if (!isOrderingOnline) {
      disableOrdering = true
    }

    const showPreTip = pretip

    // console.log(outstandingOrderConditions)

    let cashbackPercentage = Number(cashbackPercentageAsInteger)
    enableCashback=true
    showPickup=true
    showDelivery=true

    // cashback min subtotal
    if (shopId === 'kiin') {
      if (totalPrice < 30) {
        enableCashback = false
      }
    }

    maxCashOrderTotalInCents = Number(maxCashOrderTotalInCents) || 0
    const creditOnlyExceedMaxInvoice =
      (maxCashOrderTotalInCents > 1000) &&
      (invoice * 100 > maxCashOrderTotalInCents) &&
      (paymentType === "CASH")
    // console.log({invoice, maxCashOrderTotalInCents, creditOnlyExceedMaxInvoice})
    if (cart.state.paymentType === 'CASH') {
      disableOrdering = disableOrdering || creditOnlyExceedMaxInvoice
    }

    // disableCash = false
    let utensilsChecked = false
    switch (utensilsOption) {
      case 'NO_UTENSILS':
        utensilsChecked = false
        break;        
      case 'ADD_UTENSILS':
        utensilsChecked = true
        break;
      default:
        utensilsChecked = false
        break;
    }

    return (
      <div className="checkout-container">

        <Helmet>
          <title>Checkout</title>
        </Helmet>


        <nav aria-label="cart" role="navigation" className="back">
          <Link to="/order">← Back to Menu</Link>
        </nav>

        <section aria-labelledby="cart-title">
          <h2 className="cart-title"
            id="cart-title"
            ref={focusRef}
            tabIndex={-1}>Shopping Bag</h2>
          {cartHasNoItems &&
            <p>Empty Bag, Add items from <Link to="/" onClick={goBack}>our menu.</Link></p>
          }
          <ul
            aria-label="cart items"
            className="cart-items-list-checkout">
            {cart.state.products.map(item => <CartItem {...item}
              key={item.id}
              showItemCardForId={cart.showItemCardForId}
              removeFromCart={cart.removeFromCart}
             />)}
          </ul>

            {(discount > 0.5) && <div role="text" className="discounts-shopping-bag">
            <span className="specials-text">
              <strong>Discount:</strong>
              <span className="discount-amount"> -${discount}</span></span>
          </div>}
        </section>


 



        

        <hr aria-hidden className="checkout__section-divider"/>

        {isOrderingOnline ? null : <section className="offline-messege">online ordering is currently offline, you may still add items to order at a later time.</section>}


       

        <CashPayment shopId={props.shopId}></CashPayment>



        <section aria-label="fulfilment type" className="checkout__order-type-switch-container switch-button-group">
          <h2 className="checkout-section-header">Fulfilment Options</h2>
          {(process.env.NODE_ENV === 'development') && <label htmlFor="dine-in" className={(cartType === 'DINEIN') ? 'selected': 'not-selected'}>
            <input type="radio" name="orderType" id="dine-in"
              checked={cart.state.cartType === 'DELIVERY'}
              onClick={cart.setCartType.bind(this, 'DINEIN')}/>Dine-In</label>}

          {showPickup && !deliveryOnly && <label htmlFor="pickup" className={(cartType === 'PICKUP') ? 'selected': 'not-selected'}>
            <input type="radio" name="orderType" id="pickup"
              checked={cart.state.cartType === 'PICKUP'}
              onClick={cart.setCartType.bind(this, 'PICKUP')}/>Pick Up</label>}
          {!disableDelivery && <label htmlFor="delivery" className={(cartType === 'DELIVERY') ? 'selected': 'not-selected'}>
            <input type="radio" name="orderType" id="delivery"
              checked={cart.state.cartType === 'DELIVERY'}
              onClick={cart.setCartType.bind(this, 'DELIVERY')}/>Delivery</label>}
        </section>

        <section aria-label="fulfilment details" className="cart-type-container">
          {(cart.state.cartType === "DELIVERY" && !disableDelivery) &&
          <div className="checkout__delivery-widget">
            {(!notEnoughDriver) && <DeliveryAddress
              defaultOrderStartsDropoffMinutes={defaultOrderStartsDropoffMinutes}
              shopId={shopId}></DeliveryAddress>}

            {notEnoughDriver && <div style={{
              lineHeight: '1.5em',
              
            }}>{notEnoughDriverText || 'Not enough drivers in the area (Grubhub/Ubereats/Postmates/Doordash) check back later or use pickup, Sorry!'}</div>}


          </div>}

          {(cart.state.cartType === "PICKUP") &&
          <div className="checkout__pickup-widget">
  
            <div>
             
                <p>Pick Up Estimate: {approxPickupWaitInMinutes}min.</p>
                {(props.shopId === 'pablitostacosnoho')  &&
                <p>Order is usually finished in 15min if there is a short line.</p>
                }
             
              <PickReadyTime estimateInMinutes={approxPickupWaitInMinutes} ></PickReadyTime>
              

              {(props.shopId === 'pablitostacosnorth-') && <div
            // style={{color: }}

            >
            <h2>We are located in the parking lot of the Write-Off room</h2>
            <p>You will receive a text when the order is ready.</p>
            {/* <p>
              For pickup orders: cash/credit upon pickup
            </p>
            <p>
              For delivery orders: cash upon delivery or call us ahead to use your credit card
            </p> */}
          </div>}

            </div>
          </div>}

          {(cart.state.cartType === "DINEIN") &&
            <div className="checkout__dine-in-widget">
              <div>

                <DineInOptions estimateInMinutes={approxPickupWaitInMinutes} ></DineInOptions>

              </div>
            </div>}



        {(enableUtensilsPicker || (process.env.NODE_ENV === 'development')) &&
          <section aria-label="order options" className="cart-customization">
            <label 
              aria-label="would you like to include utensils?"
              className={(utensilsChecked) ? 'co-checkbox checked' : "co-checkbox"}
              htmlFor="utensils-options">
              Include Utensils?
              <input 
                checked={utensilsChecked}
                onChange={(e) => {
                  console.log('🐸',e.target.checked)
                  e.target.checked ? 
                  cart.setStateAndSave({utensilsOption: 'ADD_UTENSILS'}) :
                  cart.setStateAndSave({utensilsOption: 'NO_UTENSILS'})
                }}
                type="checkbox" name="include-utensils" id="utensils-options" />
            </label>
          </section>}
        </section>



        <hr aria-hidden className="checkout__section-divider"/>

     


        <section aria-label="payment choice" className="checkout__payment-type-switch-container switch-button-group">
          <h2 className="checkout-section-header">Payment</h2>
            {!disableCash && !(deliveryCreditOnly && cart.state.cartType === 'DELIVERY') && <label htmlFor="cash" className={(paymentType === 'CASH') ? 'selected': 'not-selected'}>
            <input type="radio" name="paymentType" id="cash"
              checked={paymentType === 'CASH'}
              onClick={cart.setStateAndSave.bind(this, {paymentType: 'CASH'})}/>
              <span className="label-text">{(cart.state.cartType === "DELIVERY") ? `Cash` : `Pay In Person`}</span>
            </label>}
            {!disableCredit && <label htmlFor="card" className={(paymentType === 'CARD') ? 'selected': 'not-selected'}>
              <input type="radio" name="paymentType" id="card"
                checked={paymentType === 'CARD'}
                onClick={cart.setStateAndSave.bind(this, {paymentType: 'CARD'})}/>
                <span className="label-text">PrePay</span>
            </label>}

        </section>

        <div className="place-order-section">

          {/* <h3 className="checkout__section-title">Payment</h3> */}

          {(props.shopId === '!siri') && <div
            // style={{color: }}

            >
            <h2>We are temporarily in-person or over-the-phone payments only</h2>
            <p>
              For pickup orders: cash/credit upon pickup
            </p>
            <p>
              For delivery orders: cash upon delivery or call us ahead to use your credit card
            </p>
          </div>}

        

        
          {(['twindragon', 'cravecafe', 'craveoaks', 'cravesunset', 'cravestudiocity'].indexOf(props.shopId) !== -1 ) && (paymentType === "CARD") && <div
            // style={{color: }}

            >
            <h2>NOTE: Bring your credit card for pickup</h2>
            <p>
              Due to increase in orders placed with stolen numbers, we may require show of credit card on pickup. Security footage will be sent to anti-fraud for disputed orders.
            </p>
          </div>}


{/* 
          {(props.shopId === 'pablitostacosburbank') && <div

            >
            <h2>Taco Tuesday Warning:</h2>
            <p>
              Expect long wait time. Orders will not be refunded today due to long wait time! Staff will try their best to update wait estimate.
            </p>
            
          </div>} */}

     

          {(paymentType === "CASH") && <div
            style={{
              lineHeight: "1.5em",
              padding: "0.5em 0"
            }}
            >
            {creditOnlyExceedMaxInvoice &&
              <div>Orders above ${maxCashOrderTotalInCents / 100} need to be paid with credit card.
              <br/>
              <a href="#" onClick={cart.setStateAndSave.bind(this, {paymentType: 'CARD'})}>switch to credit card</a>
            </div>

            }
          </div>}
          {(paymentType === "CARD") &&
          <section aria-label="payment details" className="card-section">
            {showPreTip && <CheckoutTips
              shopId={shopId}
              cartType={cartType}
              // preTipPrimaryCents={()}
              preTipTotalWithTax={preTipTotalWithTax}
            />}



            {enableDonation && 
              <section aria-label="order options donation" className="cart-customization donation">
                            
        <hr aria-hidden className="checkout__section-divider"/> 
               {/* <p className="donation-prompt">{donationPrompt}</p> */}
              <label 
                aria-label={`would you like to donate to ${donationPrompt}?`}
                className={(agreeToDonate) ? 'co-checkbox checked' : "co-checkbox"}
                htmlFor="enable-fund-donation">
                Donate to {donationPrompt}
                <input 
                  checked={!!agreeToDonate}
                  onChange={(e) => {
                    console.log('donate:',e.target.checked)
                    e.target.checked ? 
                    cart.setStateAndSave({agreeToDonate: true, }) :
                    cart.setStateAndSave({agreeToDonate: false})
                  }}
                  type="checkbox" name="restaurant-donation" id="enable-fund-donation" />
              </label>   

              <p 
                className='donation-amount-prompt'
              >donate $1.99 to help keep our kitchen open.</p>
              {/* <button 
                  className='donation-customization-button'
                  aria-label='input custom donation amount'>edit</button>  */}
                 

                              
        <hr aria-hidden className="checkout__section-divider"/>

            </section>
              // <div className="donation-section">
              //   <p className="donation-prompt">{donationPrompt}</p>

              //   <label htmlFor="enable-donation">
              //     Donate to fund:
              //     <input type="checkbox" id="enable-donation" />
              //   </label>
              // </div>
            }


         <ErrorBoundary>
              <div className="quick-checkout-wrapper">
           
                {disableOrdering ? 
                <div className="disabled-note">
                     {outstandingOrderConditions[0] && <div className="out-standing-order-conditions">
                      {outstandingOrderConditions.map(c =>
                        <div>{c}</div>
                      )}
                    </div>}
                </div>
                :
                <PaymentRequestButton
                  amount={Math.round(invoice * 100)}
                  disableOrdering={disableOrdering}
                  label="pay"
                  handleTokenEvent={handleTokenEvent}
                  ></PaymentRequestButton>}
              </div>
            </ErrorBoundary>
        
            <h2 className="instruction">Credit Card</h2>

            <StripePayment></StripePayment>

            <p
              className="stripe-secure-note"
              >securely processed by stripe</p>
          </section>}


          {(props.shopId === 'tenramen') && <div
            // style={{color: }}

            >
            <h2
              style={{
                background: "yellow",
                padding: "1em 0"
              }}
            >⚠️ ATTN: This is <strong style={{fontWeight: 600}}>Los Angeles</strong> Ten Ramen location ⚠️
            <br/>No Refunds if not canceled within 10min.
            </h2>
            {/* <p>
            
            </p> */}
          </div>}

          {(props.shopId === 'kluckin') && <div
            // style={{color: }}

            >
            <h2
              style={{
                background: "yellow",
                padding: "1em 0"
              }}
            >⚠️ Attention: We Moved to<br/> <strong style={{fontWeight: 600}}>6248 Santa Monica Blvd</strong>
            </h2>
            {/* <p>
            
            </p> */}
          </div>}

          <button
            onClick={handleCreateOrder}
            disabled={disableOrdering || !stripe}
            className="button place-order-button">{
              (orderButtonPressed) ?
              "Placing Order..."  :
              "Place your Order"
            }</button>

            {outstandingOrderConditions[0] && <div className="out-standing-order-conditions">
              {outstandingOrderConditions.map(c =>
                <div>{c}</div>
              )}
            </div>}

            {orderSubmitErrorText && <div className="error-message">
              error: {orderSubmitErrorText.text}
            </div>}
        </div>


        <section aria-label="order price summary" className="price-summary">
          <h2 className="checkout__section-title">
            Order Summary
          </h2>
          <div role="text" className="subtotal line">
            <strong>Subtotal: </strong>
            <span className="subtotal__text">${round(totalPrice, 2)}</span>
            {(numberOfMarketPriceItems !== 0) ?
              <span> + {numberOfMarketPriceItems} market priced dish</span>
              : null}
          </div>
          {(cartType === 'DELIVERY') && <div role="text" className="delivery-fee line">
            <strong>Delivery: </strong>
            <span>${deliveryFeeInCents / 100}</span>
          </div>}
          {/* {(cart.state.appliedCashback > 0) && <div role="text" className="discounts line">
            <strong>Discount:</strong>
            <span className="discount-amount">- ${cart.state.appliedCashback / 100}</span>
          </div>} */}
          {(discount > 0) && <div role="text" className="discounts line">
            <strong>Discount:</strong>
            <span className="discount-amount">- ${discount}</span>
          </div>}
          {(props.shopId === 'pablitostacosburbank') && (discount < -0.5) && <div role="text" className="discounts line">
            <strong> 20 Tacos:</strong>
            <span className="discount-amount">${-discount}</span>
          </div>}
          <div role="text" className="tax line">
            <strong>Tax: </strong>
            <span>${tax}</span>
          </div>
          {settings.allServicePercentage &&
          <div role="text" className="service-fee line">
            {/* <strong>Surcharge* ({settings.allServicePercentage}%): </strong> */}
            <strong>Temp fee:*</strong>
            <span>${allServiceFee}</span>
          </div>}

          {settings.allServicePercentage &&
          <div role="text" className="fine-print">
            {settings.allServiceFeeNote ? null : <strong>*due to rising costs, a temporary ten percent on subtotal <br/>applies to all orders online or offline. <br/>Thank you for your understanding.
            </strong>}
            <span> </span>
          </div>}
          
          {settings.allServicePercentage && settings.allServiceFeeNote && <div role="text" className="info">
            <span className="info">↳ {settings.allServiceFeeNote}</span>
          </div>}

          {showPreTip ? <div role="text" className="line">
            <strong>Tip: </strong>
            <span className="total__text">${preTipPrimaryCents / 100}</span>
          </div> : null}

          {(agreeToDonate) ? <div role="text" className="line">
            <strong>Donation: </strong>
            <span className="total__text">${restaurantDonationCents / 100}</span>
          </div> : null}


          <div role="text" className="total line">
            <strong>Total: </strong>
            <span className="total__text">${invoice}</span>
          </div>
          <hr/>
          {enableCashback && (Number(cashbackPercentage) > 0) && <div role="text" className="cashback">
            <span>Earn ${round((invoice) * cashbackPercentage / 100, 2)} in rewards for this order.</span>
            <br/>
            {/* <span>({cashbackPercentage}%)</span> */}
            <span>(get your next order discounted!)</span>
          </div>}
        </section>


      </div>
    )


}

const CartItem = (item) => {
  let {price, photo, id, note, quantity} = item
  let name = item.name && item.name.en
  let description = item.description && item.description.en
  let pickedModgroups = calcItemPriceArray(item) && calcItemPriceArray(item).pickedModgroups
  let perItemPrice = calcItemPrice(item)
  return (
    <li aria-label={`${quantity} of ${name} for ${perItemPrice} dollar each`} className="item-card">

      {/* Thumbnail */}
      {photo ? <div role="presentation" className="photo">
          <img
            role="presentation"
            alt={`${name} product photo`}
            src={makeCloudinaryUrlForPhoto(photo, {width: 100})}></img>
        </div> :
        <div className="photo-placeholder" aria-hidden={true}>

        </div>
        }
      
      <div role="text" className="item-details">
       
        <span className="checkout-quantity">
          {quantity}
        </span>
        <span aria-hidden className="multiples-sign">x</span>
        <span className="name-price">
          <h2><span className="name">{name}</span> <span className="price">${perItemPrice}</span></h2>
          <ModgroupDisplay pickedModgroups={pickedModgroups} />
          {note ? <p className="note">note: {note}</p> : null}
        </span>
      </div>
      <div role="group" aria-label={`editing options for ${name}`} className="actions">
        <Link aria-label={`modify ${name}`} role="button" className="cart-item-button" to={`/cart/${id}`}>edit</Link>
        <button aria-label={`remove ${name}`} className="cart-item-button" onClick={item.removeFromCart.bind(this, id)}>remove</button>
      </div>
    </li>
  )
}

export default (withRouter(injectShopContainer(injectCartContainer(CartCheckout))))
